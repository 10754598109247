.fc.fc-unthemed {
    .fc-event {
        .fc-title {
            white-space: normal;
        }

        &.fc-short {
            overflow: hidden;
        }
    }

    .fc-breakhour {
        border-top: 3px solid $black !important;
    }
}
