.heading {
    display: grid;
    align-items: center;
    margin: -$content-padding;
    margin-bottom: 20px;
    padding: $content-padding /2 $content-padding;
    font-weight: normal;
    background-color: $content-heading-bg;

    min-height: 54px;

    &.heading-nomargin {
        margin-bottom: 0;
    }

    @include media-breakpoint-up(xl) {
        display: flex;
    }

    @include media-breakpoint-down(md) {
        display: flex;
        padding: 5px 10px;
        margin-bottom: 5px;
    }
}

.heading-start {
    margin-bottom: 15px;
    @include media-breakpoint-up(xl) {
        margin-bottom: 0;
    }
    @include media-breakpoint-down(md) {
        max-width: 50%;
        margin-bottom: 10px;
    }
}

.heading-end {
    margin-left: auto;

    > * {
        float: left;
    }
}

.heading-title {
    line-height: $headings-line-height;
    margin-bottom: 0;
    font-size: 18px;
    color: $subsidiary;
    font-weight: 700;

    @include media-breakpoint-down(md) {
        font-size: 14px;
        align-content: center;
    }
}

.heading-subtitle {
    font-size: 15px;
    color: $subsidiary-lighter;
    font-weight: 600;
    margin-bottom: 0;

    @include media-breakpoint-down(md) {
        margin: 3px auto auto auto;
    }
}

.heading-end .dropdown{
    @include media-breakpoint-down(md) {
        max-width: 100px;
    }
}

.heading-groupview{
    @include media-breakpoint-down(md) {
        .heading-subtitle{
            display: none;
        }
    }
}
