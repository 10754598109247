/* ========================================================================
     Component: user-block
 ========================================================================== */

// for use as navbar item
.has-user-block {
    display: block;
    border: 0 !important;
    // width: 100% !important;
}

.user-block {
    position: relative;
    padding: 25px 15px;
    color: $white;

    display: flex;
    flex-flow: row nowrap;
    align-items: center;

    > .user-block-picture {
        flex: 0 0 auto;
        position: relative;
        width: 60px;
        text-align: center;
        margin-right: 15px;

        > img {
            max-width: 100%;
            height: auto;
        }
    }

    .user-block-info {
        flex: 1 1 auto;
        text-align: left;
        min-width: 0;
        padding-right: 10px;

        .user-block-name, .user-block-role {
            display: block;
        }

        .user-block-name {
            font-size: 17px;
            font-weight: 600;
            line-height: 1.05;
            color: $white;
            margin-bottom: 6px;

            &:hover, &:focus {
                color: $subsidiary-lightest;
                text-decoration: none;
            }
        }

        .user-block-role {
            font-size: 12px;
            color: #aaa;
        }

    }

}

.user-block-status {
    position: relative;

    > .circle {
        position: absolute;
        bottom: 0;
        right: 0;
        border: 2px solid #fff;
    }

}

// Adapt to collapsed mode
.aside-collapsed, .aside-collapsed-text {
    .user-block {
        padding: 15px 0 14px;
        margin: 0;
        text-align: center;

        > .user-block-picture {
            float: none;
            margin: 0  auto;
            width: 50px;

            > .user-block-status {
                display: block;
            }

        }

        .user-block-info {
            display: none;
        }

    }

}
