.notification {
    &-danger-outline {
        border: 1px solid red;
        padding: 5px 10px;
        margin-top: 5px;
    }
}

.alert {
    &-default {
        color: $black;
        background-color: $gray-200;

        .alert-content {
            margin-top: 0.6rem;
            //display: flex;

            div {
                min-width: 15%;
            }
        }
    }
}
